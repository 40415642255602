import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {graphql} from "gatsby"


export const data = graphql`query HomeQuery {
    pound: file(relativePath: {eq: "iconPound.png"}) {
      publicURL
    },
    planner: file(relativePath: {eq: "NemoBudgetPlanner.pdf"}){
        publicURL
    }
  }
  `;

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Home" lang={'en-gb'} />
      <div id="exHome" className="cfx">
          <div id="row1" className="cfx">
              <div className="twoCol cfx">
                  <div className="bdrTop"></div>
                  <div className="bdrMid cfx">
                      <section className="col1">
                          <div className="inr">
                              <div className="headingArea cfx">
                                  <div className="bubble purple cfx">
                                      <div className="inr">
                                          <h1>Welcome to Nemo</h1>
                                      </div>
                                      <div className="bot"></div>
                                  </div>
                                  <div className="intro">
                                    <h2>Settle your Nemo second charge mortgage</h2>
                                    <p>Nemo no longer offers second charge mortgages to new customers or further advances to our
                                    existing customers. However, we are fully committed to providing exceptional service to our
                                    existing customers.</p>
                                    <p>We understand that the cost of living is increasing, and it may be a good time for our customers
                                    to review their finances, including finding ways to reduce monthly expenses.</p>
                                    <p>One option is to remortgage your property to consolidate into a single mortgage with lower
                                    interest rates, potentially reducing your monthly payments significantly.</p>
                                    <p>If you are considering exploring options with another provider, you can contact an independent
                                    mortgage broker to discuss your options. Most brokers do not charge for this service. <a href="https://www.moneyhelper.org.uk/en">Money Helper</a>
                                    &nbsp;has a list of brokers who can assist you if you are interested.</p>
                                    <strong>If you would like any information about your Nemo second charge mortgage, or a statement
                                    showing how much you owe, please <a href="/contact-us">contact us.</a></strong>  
                                  </div>
                              </div>
                          </div>
                      </section>
                      <aside className="col2">
                          <div className="bubble purple cfx">
                              <div className="inr">
                                  <h2>Key information</h2>
                              </div>
                              <div className="bot"></div>
                          </div>
                          <p>Call us on&nbsp;<span
                                className="telnum"><strong>0800 138 2935</strong></span>, or email us: <a href="mailto:customerservices@nemoloans.co.uk">customerservices@nemoloans.co.uk</a></p>
                          <p>Mon to Fri: 9:30am - 5:00pm </p>
                          <strong>Make a payment</strong><div><br/></div>
                          <ul>
                            <li>Sort Code: 20 18 23</li>
                            <li>Account Number: 20069604</li>
                            <li>Payment Reference: Your Nemo account number</li>
                          </ul>
                          <strong>You can write to us at:</strong><div><br/></div>
                          <p>Nemo Personal Finance<br/>
                            Principality Buildings<br/>
                            PO Box 89<br/>
                            Queen Street<br/>
                            Cardiff<br/>
                            CF10 1UA</p>
                          
                          
                      </aside>
                  </div>
                  <div className="bdrBot"></div>
              </div>
          </div>
          <div id="row2" className="cfx">
              <div className="twoColEven cfx">
                    <section className="col1 col">
                        <div className="mid">
                            <h2>Help managing your finances</h2>
                            <p className="MsoNormal" style={{margin: '0cm 0cm 8pt'}}>
                                Understanding your outgoings is the first step to help you manage them.  It 
                                allows you to see where your money goes and where you could spend less.
                            <br /><br />
                                You can use our free budget planner to help you work out your budget.
                            </p>
                            <a href="https://www.nemo-loans.co.uk/static/919edf11feaf30050b0a0a0882b567f0/NemoBudgetPlanner.pdf" className="btn floatRight">
                                Visit our Budget Planner
                                <span className="tl"></span>
                                <span className="tr"></span>
                                <span className="bl"></span>
                                <span className="br"></span>
                            </a>
                        </div>
                    </section>
                    <section className="col2 col">
                        <div className="mid">
                            <h2>Support</h2>
                            <p className="MsoNormal" style={{margin: '0cm 0cm 8pt'}}>Dealing with unexpected and difficult situations such as serious illness, unemployment,
                            relationship breakdown, or the death of a loved one can be tough.
                            <br /><br />
                            Managing finances during such times can become even more challenging. Our Customer
                            Support Team is here to provide assistance.</p>
                            <a href="/support" className="btn floatRight">
                            Visit our Support Hub
                                <span className="tl"></span>
                                <span className="tr"></span>
                                <span className="bl"></span>
                                <span className="br"></span>
                            </a>
                        </div>
                    </section>
              </div>
          </div>
      </div>
  </Layout>
)

export default IndexPage
